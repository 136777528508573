import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

/* 引入布局 */
import Layout from '../layout/index.vue'


const routes = [
  {
    path: '/',
    component: Layout,
    redirect: '/index',
    children: [
      {
        path: 'index',
        component: () => import('../views/index.vue'),
        name: 'Index',
        meta: { title: '首页' }
      },
      {
        path: 'about',
        component: () => import('../views/about.vue'),
        name: 'About',
        meta: { title: '概况' }
      },
      {
        path: 'news',
        component: () => import('../views/news.vue'),
        name: 'News',
        meta: { title: '资讯' }
      },
      {
        path: 'education',
        component: () => import('../views/education.vue'),
        name: 'Education',
        meta: { title: '资讯' }
      },
      {
        path: 'list',
        component: () => import('../views/list.vue'),
        name: 'List',
        meta: { title: '名录' }
      },
      {
        path: 'exhibit',
        component: () => import('../views/exhibit.vue'),
        name: 'Exhibit',
        meta: { title: '展览' }
      },
      {
        path: 'academic',
        component: () => import('../views/academic.vue'),
        name: 'Academic',
        meta: { title: '学术' }
      },
      {
        path: 'video',
        component: () => import('../views/video.vue'),
        name: 'Video',
        meta: { title: '视频' }
      },
      {
        path: 'juti',
        component: () => import('../views/juti.vue'),
        name: 'Juti',
        meta: { title: '详情页' }
      },
      {
        path: 'listjuti',
        component: () => import('../views/listjuti.vue'),
        name: 'Listjuti',
        meta: { title: '名录详情' }
      },
      {
        path: 'test',
        component: () => import('../views/test.vue'),
        name: 'Test',
        meta: { title: '测试' }
      },
      {
        path: 'zixun/:id',
        component: () => import('../views/zixun.vue'),
        name: 'Zixun',
        meta: { title: '资讯' }
      },
      {
        path: 'zhan/:id',
        component: () => import('../views/zhan.vue'),
        name: 'Zhan',
        meta: { title: '展览' }
      },
      {
        path: 'xue/:id',
        component: () => import('../views/xue.vue'),
        name: 'Xue',
        meta: { title: '学术' }
      },
      {
        path: 'jiao/:id',
        component: () => import('../views/jiao.vue'),
        name: 'Jiao',
        meta: { title: '教育' }
      }, 
      {
        path: 'article/:cid/:id',
        component: () => import('../views/article.vue'),
        name: 'Article',
        meta: { title: '文章' }
      }, 
      {
        path: 'shipin/:id',
        component: () => import('../views/shipin.vue'),
        name: 'Shipin',
        meta: { title: '视频' }
      },
      {
        path: 'search/:keyword',
        component: () => import('../views/search.vue'),
        name: 'Search',
        meta: { title: '搜索' }
      }
    ]
  }
]

const router = new VueRouter({
  // mode:'history',
  routes
})

// 重复点击导航报错的处理
const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (to) {
  return VueRouterPush.call(this, to).catch(err => err)
}

export default router
