<template>
  <div class="app-wrapper">
    <div class="content">
      <div class="head">
      <div class="szgtop szgconwidth">
        <img src="../assets/logo.png" class="logoimg" alt="苏州工艺美术">
        <div class="szgsearch">
          <el-input v-model="keyword" size="medium" class="searchinput" placeholder="输入关键字"></el-input>
          <el-button type="primary" size="medium" @click="search">搜索</el-button>
        </div>
      </div>
      <div id="szgnav" class="szgnav">
        <el-menu
        :default-active="activeIndex"
        class="menunav szgconwidth"
        mode="horizontal"
        @select="handleSelect"
        background-color="#000"
        text-color="#fff">
          <el-menu-item index="Index">首页</el-menu-item>
          <el-menu-item index="About">概况</el-menu-item>
          <el-menu-item index="News">资讯</el-menu-item>
          <el-menu-item index="List">名录</el-menu-item>
          <el-menu-item index="Educate">教育</el-menu-item>
          <!-- <el-submenu index="Educate">
            <template slot="title"><router-link :to="{  }">教育</router-link></template>
            <el-menu-item>继续教育</el-menu-item>
            <el-menu-item>专技教育</el-menu-item>
            <el-menu-item>职称评定</el-menu-item>
            <el-menu-item>乡土人才评审</el-menu-item>
          </el-submenu> -->
          <el-menu-item index="Exhibit">展览</el-menu-item>
          <el-menu-item index="Academic">学术</el-menu-item>
          <!-- <el-menu-item index="Video">视频</el-menu-item> -->
          <el-menu-item index="Huojiang">获奖查询</el-menu-item>
        </el-menu>
      </div>
      </div>

      <router-view ref="cont"></router-view>
    </div>

    <div class="foot">
      <div class="szgconwidth szgfoot">
        <el-row>
          <el-col :sm="24" :md="12">
            <h2>苏州市工艺美术行业协会</h2>
            <hr>
            <p>中国 苏州西北街88号</p>
            <p>电话：0512-67511005</p>
            <p>传真：0512-67541506</p>
            <p>邮编：215001</p>
            <p>邮箱：1519449645@qq.com</p>
          </el-col>
          <el-col :sm="24" :md="12" class="szgfright">
            <p>技术支持：苏州苏作天工大数据服务有限公司</p>
            <p>版权所有：苏州市工艺美术行业协会、苏州市工艺美术学会</p>
            <p>备案号：<a href='https://beian.miit.gov.cn' target="_blank">苏ICP备2021054538号-1</a>&nbsp;&nbsp;
              <a 
                target="_blank" 
                href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=32050802011503" 
                style="display:inline-block;text-decoration:none;height:20px;line-height:20px;"
              >
                <img src="../assets/gonganbeian.png" style="float:left;"/>
                <span style="">苏公网安备 32050802011503号</span>
              </a>&nbsp;&nbsp;
            </p>
            <ul class="szgfnav">
              <li><router-link :to="{ name:'Index' }">首页</router-link></li>
              <li><router-link :to="{ name:'About' }">概况</router-link></li>
              <li><router-link :to="{ name:'News' }">资讯</router-link></li>
              <li><router-link :to="{ name:'List' }">名录</router-link></li>
              <li><a target="_blank" href='https://szacri.edusoho.cn/'>教育</a></li>
              <li><router-link :to="{ name:'Exhibit' }">展览</router-link></li>
              <li><router-link :to="{ name:'Academic' }">学术</router-link></li>
              <li><router-link :to="{ name:'Video' }">视频</router-link></li>
            </ul>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>
<script>
import 'element-ui/lib/theme-chalk/display.css'
export default {
  name: 'Layout',
  data() {
    return {
      szgsearch: '',
      activeIndex: 'Index',
      keyword: ''
    }
  },
  methods: {
      handleSelect(key) {
        // console.log(key, keyPath);
        if(key == 'Educate') {
          // window.location.href = 'https://szacri.edusoho.cn/'
          window.open('https://szacri.edusoho.cn/', '_blank');
        } else if (key == 'Huojiang') {
          window.open('http://search.ybh.org.cn/', '_blank');
        } else {
          this.$router.push('/' + key);
        }
      },
      search() {
        if(this.keyword.length>0) {
          this.$router.push('/search/' + this.keyword);
          if(this.$refs.cont.search) {
            this.$refs.cont.search(this.keyword)
          }
        }
      }
    }
}
</script>
<style scoped>

</style>